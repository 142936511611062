// MathClub
import React from 'react';
import MathClub2023 from 'views/MathClub2023';

const MathClubPage = () => {
  return <MathClub2023/>;
};

export default MathClubPage;

